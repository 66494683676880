import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { rowCreateByDate } from "../../../functions";
import ProductQuickAdd from "../../product/ProductQuickAdd";

const ProductsMoreNews = (
    {
        quantity = 9,
        title = "Lo más nuevo",
        textSize = "24px",
        textWeight = "medium",
        textAlign = "center",
        horizontalMargin = "auto"
    }) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleMediaQueryChange = (matches) => {
        // matches will be true or false based on the value for the media query
        //console.log("matches", matches);
    };

    const isMobile = useMediaQuery(
        { maxWidth: 767 },
        undefined,
        handleMediaQueryChange
    );

    useEffect(() => {
        async function fetchProducts() {
            setLoading(true);
            try {
                const response = await fetch('/api/products-more-news', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.statusText}`);
                }

                const data = await response.json();
                setProducts(rowCreateByDate(data.products.nodes, quantity));
            } catch (error) {
                console.error("Error al cargar los productos:", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        }

        fetchProducts();
    }, []);

    return (
        <div className="my-18">
            <h3 className={`all-news-title text-[${textSize}] font-${textWeight} w-full text-${textAlign} mb-6`}>
                {title}
            </h3>
            <div className={`product-more-news mx-${horizontalMargin} grid grid-cols-2 md:grid-cols-3 xl:grid-cols-3 gap-x-2 lg:gap-x-4 gap-y-10 justify-items-stretch overflow-hidden scroll-smooth`}>
                {products.length > 0 &&
                    products.map((product) => (
                        <ProductQuickAdd
                            key={product?.id}
                            product={product}
                            hideFilter={true}
                            isMobile={isMobile}
                        />
                    ))
                }
            </div>
        </div>
    );
}

export default ProductsMoreNews;
