/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import clientConfig from "../../../client-config";
import { isEmpty } from "lodash";
import Image from "next/image";

const BasicCard = ({ title, url, image, description }) => {

	return (
		<div className="product-desktop w-71 h-107.5 place-self-center">
			<div className="h-71">
				<a href={url}>
					{image ? (
						<Image
							src={image}
							alt={title}
							className="rounded-lg"
							width={282}
							height={282}
							placeholder="blur"
							blurDataURL="data:image/jpeg;base64,/9j/UIRC#uoz?ws:VYj[o}fkRjkCjYayozayf5of"
							quality={90}
						/>
					) : !isEmpty(clientConfig.productImagePlaceholder282) ? (
						<Image
							src={clientConfig.productImagePlaceholder282}
							alt={title}
							className="rounded-lg"
							width={282}
							height={282}
							quality={100}
						/>
					) : null}
				</a>
			</div>
			<div className="product-info h-37">
				<div className="flex flex-col">
					<h3 className="product-title mt-3 mb-1.5 font-helvetica-text text-sm tracking-widest font-normal text-black">
						<a href={url}>
							{title ? title : ""}
						</a>
					</h3>
					<p className="font-helvetica-text text-sm text-gray-description">{description}</p>
				</div>
			</div>
		</div>
	);
};

export default BasicCard;
