// builder-registry.ts
import { Builder } from '@builder.io/react'
import dynamic from "next/dynamic";

Builder.registerComponent(
  dynamic(() => import('./addons/heading')),
  {
    name: 'Heading',
    inputs: [
      { name: 'title', type: 'text', defaultValue: "Texto promocional" },
      { name: 'txtColor', type: 'string', defaultValue: "#000000" },
      { name: 'fontSize', type: 'string', defaultValue: "24px" },
  ],
    image: 'https://tabler-icons.io/static/tabler-icons/icons-png/3d-cube-sphere-off.png'
  }
)

Builder.registerComponent(
  dynamic(() => import('./addons/button')),
  {
    name: 'MyButton',
    inputs: [
      { name: 'content', type: 'text', defaultValue: "Click Me!" },
      { name: 'link', type: 'url', defaultValue: "#" },
      { name: 'bgColor', type: 'string', defaultValue: "#FFFFFF" },
      { name: 'txtColor', type: 'string', defaultValue: "#000000" },
      { name: 'hoverColor', type: 'string', defaultValue: "#323232" },
    ],
  }
)

import ProductQuickAddForBuilder from "./product/ProductQuickAddForBuilder";

Builder.registerComponent(ProductQuickAddForBuilder, {
  name: 'ProductQuickAddForBuilder',
  inputs: [
    { name: 'productId', type: 'string', required: true },
    { name: 'isMobile', type: 'boolean', defaultValue: false },
    { name: 'hideFilter', type: 'boolean', defaultValue: false },
    // Agrega aquí más inputs según sea necesario
  ],
});

import ProductSlider from './home/product-carousel/ProductSlider';

Builder.registerComponent(ProductSlider, {
  name: 'ProductSlider',
  inputs: [
    { name: 'categoryId', type: 'string', required: true },
    { name: 'title', type: 'string', required: false },
    { name: 'isLanding', type: 'boolean', defaultValue: false },
    { name: 'isMobile', type: 'boolean', defaultValue: false },
    { name: 'hideFilter', type: 'boolean', defaultValue: false },
  ],
});

import ProductsMoreNews from './home/more-news';

Builder.registerComponent(ProductsMoreNews, {
  name: 'ProductsMoreNews',
  inputs: [
    { name: 'quantity', type: 'number' },
    { name: 'title', type: 'string', required: false, defaultValue: "Lo más nuevo" },
    { name: 'textSize', type: 'string', defaultValue: "24px" },
    { name: 'textWeight', type: 'string', defaultValue: "bold" },
  ],
});

import BasicCard from "./card/BasicCard";

Builder.registerComponent(BasicCard, {
  name: 'BasicCard',
  inputs: [
    { name: 'title', type: 'string', required: true, defaultValue: 'titulo o nombre' },
    { name: 'url', type: 'url', defaultValue: false },
    { name: 'image', type: 'string', required: true, defaultValue: false },
    { name: 'description', type: 'longText', defaultValue: 'Aqui va la descripcion'}
    // Agrega aquí más inputs según sea necesario
  ],
});

import MyImageComponent from "./addons/MyImageComponent";

Builder.registerComponent(MyImageComponent, {
  name: 'MyImageComponent',
  inputs: [
    { name: 'src', type: 'string', required: true, defaultValue: '' },
    { name: 'alt', type: 'longText', defaultValue: 'Aqui va la descripcion'},
    { name: 'width', type: 'number', defaultValue: 300 },
    { name: 'height', type: 'number', required: true, defaultValue: 300 },   
    { name: 'quality', type: 'number', required: true, defaultValue: 90 },
    { name: 'srcSet', type: 'string', required: true, defaultValue: '' },
    // Agrega aquí más inputs según sea necesario
  ],
});